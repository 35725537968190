import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { abbreviateNumber, addCommas } from '../utils/numbers';
import Tables from './Tables';

const StyledRocketCell = styled(Tables.Cell)`
  text-align: center;
`;

const StyledRocketCellHeader = styled(Tables.HeadCell)`
  text-align: center;
`;

export const StyledExchangeCellHeader = styled(Tables.HeadCell)``;

export const StyledExchangeCell = styled(Tables.Cell)`
  padding-left: 0.25rem;
  text-align: right;
  font-size: 0.65em;
  color: ${p => p.theme.colors.grey};
`;

export const StyledMoneyCellHeader = styled(Tables.HeadCell)`
  text-align: right;
`;

export const StyledPriceCellHeader = styled(Tables.HeadCell)`
  text-align: right;
  border-left: 1px solid ${p => p.theme.colors.horizontalRule};
`;

export const StyledMoneyCellHeaderLeft = styled(Tables.HeadCell)``;

export const StyledMoneyCellHeaderBorderRight = styled(Tables.HeadCell)`
  text-align: right;
  border-right: 1px solid ${p => p.theme.colors.horizontalRule};
`;

export const StyledMoneyPercentHeader = styled(Tables.HeadCell)`
  text-align: center;
`;

export const StyledTickerLink = styled.span`
  font-weight: 600;
  color: ${p => p.theme.colors.primary};

  a,
  a:visited {
    color: ${p => p.theme.colors.primary};
  }

  :visited {
  }
`;

export const StyledTickerExchange = styled.span`
  display: block;
  font-size: 8px;
  font-weight: bold;
  padding: 0;
  margin-top: -3px;
  margin-bottom: -4px;
  opacity: 0.75;
`;

export const StyledTickerPrice = styled.span`
  a,
  a:hover {
    color: ${p => p.theme.colors.primary};
    text-decoration: none;
  }
`;

export const StyledTickerName = styled.span`
  font-size: 0.825em;
  color: ${p => p.theme.colors.grey};
`;

export const StyledTableContainer = styled.div`
  max-width: 100%;
  overflow: auto;

  margin: 30px auto 30px;
  border: 1.5px solid ${p => p.theme.colors.horizontalRule};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 20px;
`;

export const StyledMoneyCell = styled(Tables.Cell)`
  text-align: right;
  font-size: 0.85em;
`;

export const StyledMoneyCellLeft = styled(Tables.Cell)`
  font-size: 0.85em;
`;

export const StyledMoneyCellBorderRight = styled(Tables.Cell)`
  text-align: right;
  font-size: 0.85em;
  border-right: 1px solid ${p => p.theme.colors.horizontalRule};
`;

export const StyledPriceCell = styled(Tables.Cell)`
  text-align: right;
  font-size: 0.825em;
  padding-right: 0.25rem;
  font-weight: bold;
  border-left: 1px solid ${p => p.theme.colors.horizontalRule};
`;

export const StyledPriceChangeCell = styled(Tables.Cell)`
  padding-left: 0.25rem;
  padding-right: 0;
  text-align: left;
  font-size: 0.825em;
`;

export const StyledRankCell = styled(Tables.Cell)`
  word-break: keep-all;
  white-space: nowrap;
  border-right: 1px solid ${p => p.theme.colors.horizontalRule};
  text-align: right;
`;

export const StyledRankCellHeader = styled(Tables.HeadCell)`
  border-right: 1px solid ${p => p.theme.colors.horizontalRule};
  text-align: center;
`;

export const StyledTickerNameCell = styled(Tables.Cell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
`;

export const StyleLastUpdatedBlock = styled.div`
  margin-right: 5px;
  margin-top: -28px;
  margin-bottom: 35px;
  opacity: 0.45;
  font-size: 0.75em;
  display: block;
  text-align: right;
  width: 100%;
`;

const StyledNoChangeArrow = styled.span`
  color: #aaa;
  font-weight: bold;
  font-size: 27px;
  line-height: 0;
  margin-top: 2px;
  display: block;
`;

export const StyledPositiveNumber = styled.span`
  color: ${p => p.theme.colors.success};
  font-weight: bold;
  font-size: 0.9em;
`;

export const StyledNegativeNumber = styled.span`
  color: ${p => p.theme.colors.error};
  font-weight: bold;
  font-size: 0.9em;
`;

const StyledAdditionalTicker = styled.span`
  a,
  a:hover,
  a:visited {
    color: ${p => p.theme.colors.primary} !important;
  }
`;

export const StyledPricePositiveNumber = styled.span`
  color: ${p => p.theme.colors.success};
`;

export const StyledPriceNegativeNumber = styled.span`
  color: ${p => p.theme.colors.error};
`;

const StyledPostNumber = styled.span`
  font-size: 0.825em;
  opacity: 0.7;
`;

interface NumberProps {
  value: number;
  prev: number;
  max?: number;
}

interface RatioNumberProps {
  value: number;
  total: number;
  prev: number;
  prevTotal: number;
}

function FooterRankNumber({ value, prev, max = 25 }: NumberProps) {
  if (prev <= 0 || prev > max) prev = max;
  if (value === prev) return null;
  return (
    <>
      (
      {value < prev ? (
        <StyledPositiveNumber title={`Up from #${prev} to #${value}`}>+{prev - value}</StyledPositiveNumber>
      ) : (
        <StyledNegativeNumber title={`Down from #${prev} to #${value}`}>-{Math.abs(prev - value)}</StyledNegativeNumber>
      )}
      )
    </>
  );
}

function RankNumber({ value, prev, max = 25 }: NumberProps) {
  if (prev <= 0 || prev > max) prev = max;
  if (value === prev) return <StyledNoChangeArrow title="No change">⬌</StyledNoChangeArrow>;
  return (
    <>
      {value < prev ? (
        <StyledPositiveNumber title={`Up from #${prev} to #${value}`}>▲ {prev - value}</StyledPositiveNumber>
      ) : (
        <StyledNegativeNumber title={`Down from #${prev} to #${value}`}>▼ {Math.abs(prev - value)}</StyledNegativeNumber>
      )}
    </>
  );
}

export function formatExchangeName(exchangeName: string): string {
  if (exchangeName === 'Toronto') return 'TSX';
  if (exchangeName.startsWith('NYSE')) return 'NYSE';
  if (exchangeName.startsWith('Nasdaq')) return 'NASDAQ';
  if (exchangeName === 'Canadian Sec') return 'CSE';
  if (exchangeName.indexOf('OTC') >= 0) return 'OTC';
  return exchangeName;
}

export function PriceChangePercent({ value, prev }: NumberProps) {
  if (value === prev) return null;
  const diff = Math.round((value - prev) * 100) / 100;
  const percent = Math.floor((diff / prev) * 10000) / 100;
  if (percent === 0) return null;

  const diffStr = diff.toFixed(2);

  return (
    <>
      {value > prev ? (
        <StyledPricePositiveNumber title={`Up from $${prev} to $${value}`}>
          +{diffStr} (+{percent}%)
        </StyledPricePositiveNumber>
      ) : (
        <StyledPriceNegativeNumber title={`Down from $${prev} to $${value}`}>
          {diffStr} ({percent}%)
        </StyledPriceNegativeNumber>
      )}
    </>
  );
}

export function RatioNumber({ value, prev, total, prevTotal }: RatioNumberProps) {
  const multiplier = 100;
  const divider = 1;

  const current = ((value / total) * multiplier) | 0;
  const previous = ((prev / prevTotal) * multiplier) | 0;

  if (current === previous) return <>{value}</>;

  const percent = (current - previous) / divider;

  return (
    <>
      {value}
      {' ('}
      {percent > 0 ? <StyledPositiveNumber>+{percent}%</StyledPositiveNumber> : <StyledNegativeNumber>{percent}%</StyledNegativeNumber>})
    </>
  );
}

export function fixFormattedPrice(value: string): string {
  // const index = value.indexOf('.');
  // if (index === value.length - 5) return value.substr(0, value.length - 2);
  return value;
}

function VolumeDifference({ value = 0, prev = 0 }) {
  if (value === prev) return null;
  const diff = Math.round((value - prev) * 100) / 100;
  const percent = Math.floor((diff / prev) * 10000) / 100;
  if (percent === 0) return null;

  const decimals = percent < 100 ? 2 : percent < 1000 ? 1 : 0;

  return (
    <>
      {value > prev ? (
        <StyledPricePositiveNumber title={`Volume increased by ${abbreviateNumber(value - prev)} from ${abbreviateNumber(prev)}`}>
          +{percent.toFixed(decimals)}%
        </StyledPricePositiveNumber>
      ) : (
        <StyledPriceNegativeNumber title={`Volume decreased by ${abbreviateNumber(prev - value)} from ${abbreviateNumber(prev)}`}>
          {percent.toFixed(decimals)}%
        </StyledPriceNegativeNumber>
      )}
    </>
  );
}

interface TrendsTableProps {
  heading?: string;
  data: any;
  count?: number;
  postsInsteadOfComments?: boolean;
  lastUpdated?: boolean;
  volume?: boolean;
}

export function TickerSymbolAndName({ ticker }) {
  let displaySymbol = ticker.originalSymbol ?? ticker.symbol;
  if (displaySymbol.endsWith('.TO')) {
    displaySymbol = displaySymbol.substr(0, displaySymbol.length - 3);
  }
  return (
    <>
      <StyledTickerLink>
        <Link style={{ fontWeight: 600 }} to={`/${ticker.symbol}/`}>
          {displaySymbol}
        </Link>
      </StyledTickerLink>{' '}
      {!!ticker.name && <StyledTickerName>({ticker.name})</StyledTickerName>}
    </>
  );
}

export function TrendsTable({
  heading,
  data,
  postsInsteadOfComments = false,
  count = 15,
  lastUpdated = true,
  volume = true
}: TrendsTableProps) {
  const [showCount, setShowCount] = React.useState(10);

  const { tickers } = data;
  const additionalMinCommentCount = tickers.length > 10 ? Math.max(3, (tickers[9].numberOfComments * 0.6) | 0) : 3;
  const additional = tickers.filter(
    (t, index: number) =>
      index >= showCount &&
      index < Math.min(count, showCount + 5) &&
      (t.numberOfComments >= 20 || t.numberOfComments >= additionalMinCommentCount)
  );
  const showRocketsCell = false; // tickers.some(t => t.numberOfCommentsWithRocketEmojis > 10);
  const showVolume = volume;
  const slashRIndex = data.post && data.post.url.indexOf('/r/') + 3;
  const subredditName = slashRIndex > 3 ? data.post.url.substr(slashRIndex, data.post.url.indexOf('/', slashRIndex) - slashRIndex) : '';
  const showPosts = tickers.some(t => t.numberOfPosts > 1);
  const canShowMore = additional.length > 2;

  function PostNumber({ posts = 0 }) {
    if (posts === 0) return null;
    return (
      <StyledPostNumber>
        / {posts} post{posts > 1 ? 's' : ''}
      </StyledPostNumber>
    );
  }

  function handleShowMore() {
    setShowCount(showCount + additional.length);
  }

  return (
    <>
      {!!heading && <h1>{heading}</h1>}
      {!!data.post &&
        (subredditName !== 'wallstreetbetsOGs' ? (
          <p>
            Top stock tickers in the {subredditName || 'wallstreetbets'} <a href={data.post.url}>{data.post.title}</a>.
          </p>
        ) : (
          <p>Top stock tickers in a private wallstreetbets subreddits '{data.post.title}' thread.</p>
        ))}
      <Tables.Table>
        <Tables.Head>
          <tr>
            <StyledRankCellHeader>#</StyledRankCellHeader>
            <Tables.HeadCell title="The ticker symbol and company name">Symbol (Name)</Tables.HeadCell>
            <StyledExchangeCellHeader title="The exchange the ticker trades on">&nbsp;</StyledExchangeCellHeader>
            <Tables.HeadCell title="The relative rank (momentum) compared to yesterday, green arrow means more popular, red less and gray no change">
              Rank
            </Tables.HeadCell>
            <Tables.HeadCell title="The number of comments (and posts) mentioning the ticker symbol or company name">
              Comments
            </Tables.HeadCell>
            <Tables.HeadCell title="The number of unique users commenting with the ticker symbol">Users</Tables.HeadCell>
            {showRocketsCell && (
              <StyledRocketCellHeader title="The number of posts with the ticker symbol and rocket emojis">🚀</StyledRocketCellHeader>
            )}
            <StyledPriceCellHeader title="The last closing price">Price</StyledPriceCellHeader>
            <StyledMoneyPercentHeader title="The change between the closing price of Yesterday and Today" />
            {showVolume && <Tables.HeadCell title="The volume in the last day of trading">Vol</Tables.HeadCell>}
            {showVolume && (
              <StyledMoneyPercentHeader title="The increase in volume compared to the previous day">24h</StyledMoneyPercentHeader>
            )}
            <StyledMoneyCellHeader title="The market cap">Mrkt Cap.</StyledMoneyCellHeader>
          </tr>
        </Tables.Head>
        <Tables.Body>
          {tickers
            .filter((_, index: number) => index < showCount)
            .map((t, index) => (
              <tr key={t.symbol}>
                <StyledRankCell>{index + 1}.</StyledRankCell>
                <StyledTickerNameCell title={t.description}>
                  <TickerSymbolAndName ticker={t} />
                </StyledTickerNameCell>
                <StyledExchangeCell title={`${t.exchangeName} (${t.exchange})`}>{formatExchangeName(t.exchangeName)}</StyledExchangeCell>
                <Tables.Cell>
                  <RankNumber value={t.rank} prev={t.previousRank} max={count + 10} />
                </Tables.Cell>
                <Tables.Cell>
                  {addCommas(t.numberOfComments)} {showPosts && <PostNumber posts={t.numberOfPosts} />}
                </Tables.Cell>
                <Tables.Cell>
                  {t.numberOfUsers}
                  {/* <RatioNumber
                      value={t.numberOfUsers}
                      prev={t.numberOfUsers - t.changeUsers}
                      total={data.post?.commentCount ?? 0}
                      prevTotal={data.previousPostCommentCount}
                    /> */}
                </Tables.Cell>
                {showRocketsCell && (
                  <StyledRocketCell>{t.numberOfCommentsWithRocketEmojis > 0 ? t.numberOfCommentsWithRocketEmojis : ''}</StyledRocketCell>
                )}
                <StyledPriceCell>
                  <StyledTickerPrice>
                    <a href={`https://finance.yahoo.com/quote/${t.symbol}/chart`}>
                      {t.currencySymbol} {fixFormattedPrice(t.priceFormatted || '')}
                    </a>
                  </StyledTickerPrice>
                </StyledPriceCell>
                <StyledPriceChangeCell>
                  <PriceChangePercent value={t.price} prev={t.previousClosingPrice || ''} />
                </StyledPriceChangeCell>
                {showVolume && (
                  <StyledMoneyCell title={t.volume ? addCommas(t.volume.volume) : undefined}>
                    {!!t.volume && t.volume.volume > 0 && <>{abbreviateNumber(t.volume.volume)}</>}
                  </StyledMoneyCell>
                )}
                {showVolume && (
                  <StyledPriceChangeCell>
                    {!!t.volume && t.volume.volume24h > 0 && <VolumeDifference value={t.volume.volume} prev={t.volume.volume24h} />}
                  </StyledPriceChangeCell>
                )}
                <StyledMoneyCell>
                  {t.marketCap && (
                    <>
                      {t.currencySymbol} {t.marketCap}
                    </>
                  )}
                </StyledMoneyCell>
              </tr>
            ))}
        </Tables.Body>
        {additional.length > 2 && (
          <Tables.Foot>
            <tr>
              <Tables.FootCell colSpan={showRocketsCell ? 10 : 9} style={{ opacity: 0.95 }}>
                <b>
                  Ranks {showCount + 1}-{Math.min(count, showCount + additional.length)}:{' '}
                </b>
                {additional.map((t, index) => (
                  <StyledAdditionalTicker key={t.symbol} title={`${t.name} with ${t.numberOfComments} comments`}>
                    <StyledTickerLink>
                      <Link to={`/${t.symbol}/`}>{t.originalSymbol ?? t.symbol}</Link>
                    </StyledTickerLink>{' '}
                    {t.rank !== t.previousRank ? ' ' : ''}
                    <FooterRankNumber value={t.rank} prev={t.previousRank} max={count + 10} />
                    {index + 1 < additional.length ? ', ' : ''}
                  </StyledAdditionalTicker>
                ))}
              </Tables.FootCell>
              <Tables.FootCell colSpan={2} style={{ opacity: 0.95, textAlign: 'right' }}>
                {canShowMore && (
                  <span role="button" onClick={handleShowMore} onKeyDown={handleShowMore} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                    Expand [+]
                  </span>
                )}
              </Tables.FootCell>
            </tr>
          </Tables.Foot>
        )}
      </Tables.Table>
      {lastUpdated && <StyleLastUpdatedBlock>Last Updated: {data.lastUpdatedEst}</StyleLastUpdatedBlock>}
    </>
  );
}
